import React, { Fragment } from 'react';
import { shape, string, func, arrayOf } from 'prop-types';
import Select, { components } from 'react-select';
import cx from 'classnames';
import globe from 'assets/images/globe.svg';

import styles from './styles.module.scss';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <img
      src={globe}
      alt="globe"
      className={styles.indicator}
    />
  </components.DropdownIndicator>
);

const LanguageSelect = ({
  value,
  className,
  onChange,
  options,
}) => (
  <Fragment>
    <Select
      className={cx(styles.languageSelect, className)}
      classNamePrefix="languageSelect"
      isSearchable={false}
      value={value}
      onChange={onChange}
      options={options}
      components={{ DropdownIndicator }}
      menuPlacement="bottom"
    />
  </Fragment>
);

LanguageSelect.propTypes = {
  value: shape({ label: string, value: string }),
  className: string,
  onChange: func,
  options: arrayOf(shape({ label: string, value: string })),
};

LanguageSelect.defaultProps = {
  value: null,
  className: '',
  onChange: null,
  options: [],
};

export default LanguageSelect;
