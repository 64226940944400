import React, { Fragment, useState, useEffect } from 'react';
import { bool, number, objectOf, string } from 'prop-types';
import { connect } from 'react-redux';

import { DELAY_500, DELAY_1000, DELAY_1500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import {
  chatCustomerAddressSelector,
  chatCustomerLastAddressSelector,
  chatIsPickUp,
  chatIsMobileTechnician,
  chatSkipPickUpSelector,
  chatSkipMobileTechnician,
  chatPickUpAvailableSelector,
  chatMobileTechnicianAvailableSelector,
  chatCustomerAddressDataSelector,
  chatCustomerLastAddressDataSelector,
} from 'selectors/booking/chat-selectors';
import { appPickupEnabled, appMobileTechnicianEnabled, appPickupNoteForCustomers } from 'selectors/booking/app-selectors';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';
import Animation from 'components/common/Animation';
import InfoMessage from 'components/common/InfoMessage';
import { appDealershipIdSelector } from 'selectors/visit/app-selectors';
import bulbIcon from 'assets/images/bulb.svg';

const errorToMessage = (error, isMobileTechnician) => {
  if (error === 'Distance above limit') {
    return `Distance above limit. Do you want to cancel the ${isMobileTechnician ? 'mobile technician' : 'pick-up'} request or select a different address?`;
  }
  if (error === 'Could not geocode given address') {
    return `I couldn’t recognize this address, Please try again or cancel ${isMobileTechnician ? 'mobile technician' : 'pick-up'} request.`;
  }
  return '';
};

const RemoteServiceSelectionStep = ({
  customerAddress,
  customerAddressData,
  customerLastAddress,
  customerLastAddressData,
  preferredAddress,
  errors,
  isConfirmed,
  isDenied,
  isCanceled,
  isComplete,
  isIntroHidden,
  isAnimationFinished,
  isProvidingNewAddress,
  isPickUp,
  isMobileTechnician,
  skipPickUp,
  skipMobileTechnician,
  pickUpEnabled,
  mobileTechnicianEnabled,
  pickUpAvailable,
  mobileTechnicianAvailable,
  dealershipId,
  pickupNoteForCustomers,
}) => {
  const [pickUpMessage, setPickUpMessage] = useState('');
  const [techniciansMessage, setTechniciansMessage] = useState('');
  const [pickUpMessageSource, setPickUpMessageSource] = useState('');
  const [techniciansMessageSource, setTechniciansMessageSource] = useState('');

  const fordDealerships = dealershipId === 67 || dealershipId === 77 || dealershipId === 60;

  useEffect(() => {
    if (isCanceled) {
      setPickUpMessage('Your pickup request has been canceled.');
      setPickUpMessageSource(SOURCE_CONCIERGE);
    } else {
      setPickUpMessage('No, no need.');
      setPickUpMessageSource(SOURCE_USER);
    }
  }, [skipPickUp]);

  useEffect(() => {
    if (isCanceled) {
      setTechniciansMessage('Your mobile technician request has been canceled.');
      setTechniciansMessageSource(SOURCE_CONCIERGE);
    } else {
      setTechniciansMessage('No, no need.');
      setTechniciansMessageSource(SOURCE_USER);
    }
  }, [isMobileTechnician]);

  const validAddress = (customerAddress && customerAddressData.address_line1)
  || (customerLastAddress && customerLastAddressData.address_line1);

  const addressSection = () => (
    <Fragment>
      {!isIntroHidden && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage message="Yes, Please." source={SOURCE_USER} />
      </AnimationGroup>
        )}
      {validAddress ? (
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            delay={DELAY_500}
            message={`${!isIntroHidden ? 'Great!' : ''} Is this the address?`}
            source={SOURCE_CONCIERGE}
            isOpening
          />
          <AnimatedTextMessage
            delay={DELAY_1000}
            message={customerLastAddress || customerAddress}
            source={SOURCE_CONCIERGE}
            timecodes={{ from: 83000, to: 89000 }}
          />
          {isProvidingNewAddress ? (
            <AnimatedTextMessage
              source={SOURCE_CONCIERGE}
              message={'Please, enter your address (you won\'t be able to change it later).'}
              isClosing
            />
            ) : null}
        </AnimationGroup>
        ) : (
          <AnimationGroup isComplete={isAnimationFinished}>
            <AnimatedTextMessage
              delay={DELAY_500}
              message="Please, enter your address."
              source={SOURCE_CONCIERGE}
              isOpening
            />
          </AnimationGroup>
        )}
      <Fragment>
        {isComplete && preferredAddress && (
          <Fragment>
            <AnimationGroup isComplete={isAnimationFinished}>
              {preferredAddress === (customerLastAddress || customerAddress) ? (
                <AnimatedTextMessage
                  message="Yes. It's correct."
                  source={SOURCE_USER}
                />
                  ) : (
                    <AnimatedTextMessage
                      message={`Use this one: \n${preferredAddress}.`}
                      source={SOURCE_USER}
                    />
                  )}
            </AnimationGroup>
            {errors && (
              <AnimationGroup isComplete={isAnimationFinished}>
                <AnimatedTextMessage
                  delay={DELAY_500}
                  message={errorToMessage(errors, isMobileTechnician)}
                  source={SOURCE_CONCIERGE}
                  isOpening
                />
              </AnimationGroup>
            )}
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  );

  return (
    <Fragment>
      {!isIntroHidden && pickUpEnabled && pickUpAvailable && (
        <AnimationGroup isComplete={isAnimationFinished}>
          <AnimatedTextMessage
            delay={fordDealerships ? DELAY_1500 : DELAY_1000}
            message="Would you like us to pick-up the vehicle from your location?"
            source={SOURCE_CONCIERGE}
            isOpening
            timecodes={{ from: 76000, to: 83000 }}
          />
          {pickupNoteForCustomers && (
            <Animation delay={DELAY_1500}>
              <InfoMessage
                label=""
                content={pickupNoteForCustomers}
              />
            </Animation>
          )}
        </AnimationGroup>
    )}
      {isConfirmed && isPickUp && addressSection()}
      {isDenied && skipPickUp && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={pickUpMessage}
          source={pickUpMessageSource}
          delay={DELAY_500}
        />
      </AnimationGroup>
    )}
      {!isIntroHidden
      && (
      ((skipPickUp || !pickUpAvailable) && mobileTechnicianEnabled && mobileTechnicianAvailable)
      || (!skipPickUp && !pickUpEnabled)
    ) && (
      <AnimationGroup isComplete={isAnimationFinished}>
        {fordDealerships &&
          <Animation delay={DELAY_500}>
            <InfoMessage
              icon={bulbIcon}
              content="Ford Mobile Service. Have our mobile technicians come to you. It's complimentary."
            />
          </Animation>
        }
        <AnimatedTextMessage
          delay={dealershipId === 60 ? DELAY_1000 : DELAY_500}
          message="Would you like to have our mobile technician service your vehicle at your location?"
          source={SOURCE_CONCIERGE}
          isOpening
          timecodes={{ from: 103000, to: 106000 }}
        />
      </AnimationGroup>
    )}
      {isConfirmed && isMobileTechnician && addressSection()}
      {isDenied && skipMobileTechnician && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={techniciansMessage}
          source={techniciansMessageSource}
          delay={DELAY_500}
        />
      </AnimationGroup>
      )}
    </Fragment>
  );
};

RemoteServiceSelectionStep.propTypes = {
  customerAddress: string.isRequired,
  customerAddressData: objectOf(string),
  customerLastAddress: string,
  customerLastAddressData: objectOf(string),
  preferredAddress: string,
  isConfirmed: bool,
  isDenied: bool,
  isCanceled: bool,
  isComplete: bool,
  isIntroHidden: bool,
  isAnimationFinished: bool,
  isProvidingNewAddress: bool,
  errors: string,
  isPickUp: bool.isRequired,
  isMobileTechnician: bool.isRequired,
  skipPickUp: bool.isRequired,
  skipMobileTechnician: bool.isRequired,
  pickUpEnabled: bool.isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  pickUpAvailable: bool.isRequired,
  mobileTechnicianAvailable: bool.isRequired,
  dealershipId: number.isRequired,
  pickupNoteForCustomers: string,
};

RemoteServiceSelectionStep.defaultProps = {
  preferredAddress: '',
  customerAddressData: null,
  customerLastAddress: '',
  customerLastAddressData: null,
  errors: '',
  isConfirmed: false,
  isDenied: false,
  isCanceled: false,
  isComplete: false,
  isIntroHidden: false,
  isAnimationFinished: false,
  isProvidingNewAddress: false,
  pickupNoteForCustomers: '',
};

const mapStateToProps = state => ({
  customerAddress: chatCustomerAddressSelector(state),
  customerAddressData: chatCustomerAddressDataSelector(state),
  customerLastAddress: chatCustomerLastAddressSelector(state),
  customerLastAddressData: chatCustomerLastAddressDataSelector(state),
  isPickUp: chatIsPickUp(state),
  isMobileTechnician: chatIsMobileTechnician(state),
  skipPickUp: chatSkipPickUpSelector(state),
  skipMobileTechnician: chatSkipMobileTechnician(state),
  pickUpEnabled: appPickupEnabled(state),
  mobileTechnicianEnabled: appMobileTechnicianEnabled(state),
  pickUpAvailable: chatPickUpAvailableSelector(state),
  pickupNoteForCustomers: appPickupNoteForCustomers(state),
  mobileTechnicianAvailable: chatMobileTechnicianAvailableSelector(state),
  dealershipId: appDealershipIdSelector(state),
});

export default connect(mapStateToProps, null)(RemoteServiceSelectionStep);
