import { EXIT, SET_EMBED_WIDGET_OPEN } from 'actions/booking/app-actions';
import {
  SET_DEALERSHIP_ID,
  SET_FLAT_MODE,
  START_BOOKING_SUCCESS,
  FETCH_TEAM_TAGS_SUCCESS,
} from 'actions/booking/chat-actions';

const initialState = {
  bookingId: null,
  actionCableChannelKey: null,
  dealership: null,
  flatMode: false,
  embedWidgetOpen: false,
  teamTags: [],
};

const app = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EXIT:
      return {
        ...state,
        bookingId: null,
      };
    case SET_DEALERSHIP_ID:
      return {
        ...state,
        dealership: {
          ...state.dealership,
          id: payload.dealershipId,
        },
      };
    case SET_FLAT_MODE:
      return {
        ...state,
        flatMode: payload.flatMode,
      };
    case SET_EMBED_WIDGET_OPEN:
      return {
        ...state,
        embedWidgetOpen: payload,
      };
    case START_BOOKING_SUCCESS: {
      const {
        id,
        name,
        time_zone: timeZone,
        tax_rate: taxRate,
        dms_type: dmsType,
        phone,
        services_price_visible: servicesPriceVisible,
        additional_settings: additionalSettings,
        phantom_advisor_enabled: phantomAdvisorAvailable,
        pickup_enabled: pickupEnabled,
        mobile_technician_enabled: mobileTechnicianEnabled,
        mobile_technician_segments: mobileTechnicianSegments,
        mobile_technician_time_variance: mobileTechnicianTimeVariance,
        pickup_time_variance: pickupTimeVariance,
        pickup_segments: pickupSegments,
        pickup_note_for_customers: pickupNoteForCustomers,
        localization_for_customers_enabled: localizationForCustomersEnabled,
        latitude,
        longitude,
        widget_avatar_enabled: widgetAvatarEnabled,
      } = payload.dealership;

      return {
        ...state,
        bookingId: payload.id,
        actionCableChannelKey: payload.channel_key,
        dealership: {
          ...state.dealership,
          id,
          timeZone,
          name,
          taxRate,
          dmsType,
          phone,
          servicesPriceVisible,
          defaultMake: additionalSettings && additionalSettings.default_make,
          dealerCode: additionalSettings && additionalSettings.dealer_code,
          phantomAdvisorAvailable,
          pickupEnabled,
          mobileTechnicianEnabled,
          mobileTechnicianSegments,
          mobileTechnicianTimeVariance,
          pickupTimeVariance,
          pickupSegments,
          pickupNoteForCustomers,
          localizationForCustomersEnabled,
          dealershipPosition: {
            lat: latitude,
            lng: longitude,
          },
          widgetAvatarEnabled,
        },
      };
    }
    case FETCH_TEAM_TAGS_SUCCESS: {
      return {
        ...state,
        teamTags: payload,
      };
    }
    default:
      return state;
  }
};

export default app;
