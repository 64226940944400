import { format } from 'date-fns';
import { isEmpty, isNil } from 'ramda';
import { geocodeByAddress } from 'react-places-autocomplete';
import { extractPhoneNumberFromString } from 'shared/utils';
import {
  phoneNumberFormatValidator,
  phoneNumberLengthValidator,
} from 'shared/validators';
import {
  startBooking,
  fetchBookingCustomer,
  addCustomer,
  fetchMakeModelYearMap,
  addBookingVehicle,
  fetchBookingServices,
  addBookingAppointment,
  updateBookingVehicle,
  fetchJobsEnabledMoneyDurationValidation,
  fetchJobsEnabledDistanceValidation,
  fetchDriverJobSlots,
  fetchAppointmentSlots,
  fetchRecallsForBooking,
  fetchAvailableAdvisors,
  fetchAvailableTransportsInWidget,
  fetchResponsibleTeamTag,
  fetchTeamAdvisors,
  fetchTeamTags,
  fetchTechnicianJobSlots,
  fetchDrivers,
  fetchTechnicians,
  updateStatistics,
} from 'shared/api';

import {
  BUSINESS_CUSTOMER_TYPE,
  ID_METHOD_PHONE_NUMBER,
  ID_METHOD_EMAIL,
} from 'shared/constants';

import {
  WELCOME_STEP,
  CONFIRM_IDENTITY_STEP,
  CAR_SELECTION_STEP,
  CUSTOMER_IDENTIFICATION_STEP,
  CUSTOMER_CREATION_STEP,
  CAR_CREATION_STEP,
  MILEAGE_STEP,
  SERVICE_SELECTION_STEP,
  ADVISOR_SELECTION_STEP,
  DATE_SELECTION_STEP,
  TRANSPORT_SELECTION_STEP,
  JOB_TRANSPORT_SELECTION_STEP,
  BOOKING_SUMMARY_STEP,
  COMMUNICATION_STEP,
  DATE_RESELECTION_STEP,
  APPRAISAL_SELECTION_STEP,
  FINAL_STEP,
  ERROR_STEP,
  REMOTE_SERVICE_STEP,
} from 'shared/constants/booking-steps';
import { DELAY_500, DELAY_1000, DELAY_1500 } from 'shared/constants/delays';

import {
  chatPreferredPhoneNumberSelector,
  chatPhoneNumberSelector,
  chatCurrentVehicleSelector,
  chatCurrentCustomerSelector,
  chatLastRequestSelector,
  chatAllSelectedServicesSelector,
  chatCustomerAddressSelector,
  chatCustomerAddressDataSelector,
  chatCustomerTypeSelector,
  chatBookingSelector,
  chatSelectedDateTimeSelector,
  chatCustomerLastAddressDataSelector,
  chatCustomerLastAddressSelector,
  chatResponsibleTeamTagSelector,
  chatSelectedOtherCustomersSelector,
  chatSelectedAllCustomersSelector,
  chatSelectedRecallsSelector,
  chatBookingSource,
  chatIsPickUp,
  chatIsDropOff,
  chatIsMobileTechnician,
  chatCurrentVehicleIdSelector,
  chatSkipPickUpSelector,
  chatRemoteZoneSelector,
  chatMobileTechnicianAvailableSelector,
  chatPickUpAvailableSelector,
  chatPreferredAddressDataSelector,
  chatCustomerWaitingSelector,
} from 'selectors/booking/chat-selectors';
import {
  appDealershipIdSelector,
  appBookingIdSelector,
  appFlatModeSelector,
  appDealershipDmsTypeSelector,
  appPickupEnabled,
  appMobileTechnicianEnabled,
} from 'selectors/booking/app-selectors';

import { twelveHourTimeFormat } from 'shared/timeSlotUtils';
import { formattedAddressData } from 'shared/getAddressComponents';

import { actionCableListener, FETCH_VEHICLES_RESPONSE_TYPE, SET_EMBED_WIDGET_OPEN } from './app-actions';

export const CHANGE_STEP = 'CHANGE_STEP';
export const UNDO_STEP = 'UNDO_STEP';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';

export const SET_CHAT_LOADING_STATE = 'SET_CHAT_LOADING_STATE';

export const START_BOOKING_SUCCESS = 'START_BOOKING_SUCCESS';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_ADVISORS_SUCCESS = 'FETCH_ADVISORS_SUCCESS';
export const FETCH_TEAM_TAG_SUCCESS = 'FETCH_TEAM_TAG_SUCCESS';

export const FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS = 'FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS';
export const FETCH_AVAILABLE_TRANSPORTS_SUCCESS = 'FETCH_AVAILABLE_TRANSPORTS_SUCCESS';
export const FETCH_AVAILABLE_PICKUP_JOBS_SUCCESS = 'FETCH_AVAILABLE_PICKUP_JOBS_SUCCESS';
export const DENY_PICKUP_JOBS_CREATION = 'DENY_PICKUP_JOBS_CREATION';
export const FETCH_APPOINTMENT_SLOTS_SUCCESS = 'FETCH_APPOINTMENT_SLOTS_SUCCESS';
export const FETCH_JOB_SLOTS_SUCCESS = 'FETCH_JOB_SLOTS_SUCCESS';
export const FETCH_TEAM_TAGS_SUCCESS = 'FETCH_TEAM_TAGS_SUCCESS';

export const SET_DEALERSHIP_ID = 'SET_DEALERSHIP_ID';
export const SET_FLAT_MODE = 'SET_FLAT_MODE';
export const SET_SKIP_STEPS = 'SET_SKIP_STEPS';
export const SET_SKIP_STEPS_TO_MILAGE = 'SET_SKIP_STEPS_TO_MILAGE';
export const SET_SKIP_STEPS_TO_CAR_SELECTION = 'SET_SKIP_STEPS_TO_CAR_SELECTION';
export const SET_SKIP_STEPS_TO_CONFIRM_IDENTITY_STEP = 'SET_SKIP_STEPS_TO_CONFIRM_IDENTITY_STEP';
export const SET_ID_METHOD = 'SET_ID_METHOD';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_PREFERRED_ADDRESS = 'SET_PREFERRED_ADDRESS';
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const SET_CURRENT_VEHICLE = 'SET_CURRENT_VEHICLE';
export const UPDATE_MILEAGE = 'UPDATE_MILEAGE';
export const SELECT_SERVICES = 'SELECT_SERVICES';
export const SET_AVAILABLE_RECALLS = 'SET_AVAILABLE_RECALLS';
export const TOGGLE_RECALL = 'TOGGLE_RECALL';
export const CLEAR_SELECTED_RECALLS = 'CLEAR_SELECTED_RECALLS';
export const SELECT_ADVISOR = 'SELECT_ADVISOR';
export const SELECT_ADVISOR_DATE = 'SELECT_ADVISOR_DATE';
export const SET_ADVISOR_IDS = 'SET_ADVISOR_IDS';
export const SELECT_DATE = 'SELECT_DATE';
export const SELECT_JOB_DATE = 'SELECT_JOB_DATE';
export const SELECT_TRANSPORT = 'SELECT_TRANSPORT';
export const SET_CLIENT_WAITING = 'SET_CLIENT_WAITING';
export const SET_SEND_SMS = 'SET_SEND_SMS';
export const SET_VEHICLE_ID = 'SET_VEHICLE_ID';
export const SET_VEHICLE_ID_FROM_PARAMS = 'SET_VEHICLE_ID_FROM_PARAMS';
export const SET_LAST_REQUEST = 'SET_LAST_REQUEST';
export const MARK_DONE_ANIMATIONS = 'MARK_DONE_ANIMATIONS';
export const SET_APPRAISAL_REQUESTED = 'SET_APPRAISAL_REQUESTED';
export const SET_JOB_REACHABLE = 'SET_JOB_REACHABLE';
export const SET_BOOKING_SOURCE = 'SET_BOOKING_SOURCE';
export const SET_IS_PICK_UP = 'SET_IS_PICK_UP';
export const SET_SKIP_PICK_UP = 'SET_SKIP_PICK_UP';
export const SET_IS_DROP_OFF = 'SET_IS_DROP_OFF';
export const SET_IS_MOBILE_TECHNICIAN = 'SET_IS_MOBILE_TECHNICIAN';
export const FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS';
export const FETCH_TECHNICIANS_SUCCESS = 'FETCH_TECHNICIANS_SUCCESS';
export const SET_REMOTE_ZONE = 'SET_REMOTE_ZONE';
export const SET_SKIP_MOBILE_TECHNICIAN = 'SET_SKIP_MOBILE_TECHNICIAN';
export const SET_JOB_TYPE = 'SET_JOB_TYPE';
export const SET_REMOTE_GEOLOCATION = 'SET_REMOTE_GEOLOCATION';
export const SET_PICK_UP_AVAILABLE = 'SET_PICK_UP_AVAILABLE';
export const SET_MOBILE_TECHNICIAN_AVAILABLE = 'SET_MOBILE_TECHNICIAN_AVAILABLE';

export const SET_TIMECODES = 'SET_TIMECODES';

const setLastRequest = request => ({ type: SET_LAST_REQUEST, payload: { request } });

export const setLoadingState = (value = true, delay = DELAY_500) => ({
  type: SET_CHAT_LOADING_STATE,
  payload: { value, delay },
});

export const replayLastRequest = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  chatLastRequestSelector(getState())();
};

export const initializeStep = (step, props, inputProps) => ({
  type: CHANGE_STEP,
  payload: { step, props, inputProps },
});

export const updateCurrentStep = (props, inputProps) => ({
  type: UPDATE_CURRENT_STEP,
  payload: { props, inputProps },
});

export const undoCurrentStep = () => ({
  type: UNDO_STEP,
});

export const setIsPickUp = value => ({
  type: SET_IS_PICK_UP,
  payload: value,
});

export const setSkipPickUp = value => ({
  type: SET_SKIP_PICK_UP,
  payload: value,
});

export const setIsDropOff = value => ({
  type: SET_IS_DROP_OFF,
  payload: value,
});

export const setIsMobileTechnician = value => ({
  type: SET_IS_MOBILE_TECHNICIAN,
  payload: value,
});

export const setSkipMobileTechnician = value => ({
  type: SET_SKIP_MOBILE_TECHNICIAN,
  payload: value,
});

export const setPickUpAvailable = value => ({
  type: SET_PICK_UP_AVAILABLE,
  payload: value,
});

export const initializeErrorStep = (error, canRetry) => (dispatch) => {
  const message = error instanceof Object ? error.message : error;
  dispatch(setLoadingState(false));
  dispatch(initializeStep(ERROR_STEP, { error: message, canRetry }, { canRetry }));
};

export const initializeWelcomeStep = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  const request = () => (
    startBooking({
      dealership_id: appDealershipIdSelector(getState()),
      source: chatBookingSource(getState()),
      phone_number: chatPreferredPhoneNumberSelector(getState()),
    })
      .then((response) => {
        dispatch({ type: START_BOOKING_SUCCESS, payload: response });

        const phoneNumber = chatPreferredPhoneNumberSelector(getState());

        if (phoneNumber) {
          const searchType = 'phone_number';
          const searchPhrase = phoneNumber;

          const requestFetchBookingCustomer = () => (
            fetchBookingCustomer(
              appBookingIdSelector(getState()),
              searchPhrase,
              searchType,
            ).catch(error => dispatch(initializeErrorStep(error, true)))
          );

          dispatch(setLastRequest(requestFetchBookingCustomer));
          requestFetchBookingCustomer();
        } else {
          dispatch(setLoadingState(false));
          const flatMode = appFlatModeSelector(getState());
          dispatch(initializeStep(WELCOME_STEP, { flatMode }, { flatMode }));
        }

        return response.id;
      })
      .then(bookingId => fetchTeamTags(bookingId))
      .then(response => dispatch({ type: FETCH_TEAM_TAGS_SUCCESS, payload: response }))
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const updateBookingStatistics = params => (dispatch, getState) => {
  updateStatistics(
    appBookingIdSelector(getState()),
    {
      ...params,
      customer_id: chatCurrentCustomerSelector(getState()).id,
    },
  );
};

export const initializeBooking = (
  dealershipId,
  flatMode,
  phone,
  vehicleId,
) => (dispatch, getState) => {
  if (dealershipId) {
    dispatch({ type: SET_DEALERSHIP_ID, payload: { dealershipId } });

    if (flatMode) {
      dispatch({ type: SET_FLAT_MODE, payload: { flatMode } });
    }

    if (phone) {
      let phoneNumber = null;
      if (phoneNumberFormatValidator(phone)) {
        phoneNumber = extractPhoneNumberFromString(phone);
      }
      if (
        phoneNumber &&
        phoneNumberLengthValidator(phoneNumber, appDealershipDmsTypeSelector(getState()))
      ) {
        dispatch({ type: SET_SKIP_STEPS, payload: true });
        dispatch({ type: SET_PHONE_NUMBER, payload: { preferredPhoneNumber: phoneNumber } });
        dispatch({ type: SET_ID_METHOD, payload: { method: ID_METHOD_PHONE_NUMBER } });
        dispatch({ type: SET_VEHICLE_ID_FROM_PARAMS, payload: vehicleId });
      }

      dispatch(initializeWelcomeStep());
    } else {
      dispatch(initializeStep(WELCOME_STEP, { flatMode }, { flatMode }));
    }
  } else {
    dispatch(initializeErrorStep('Unable to recognize the target Dealership. Please check if you have a correct link.'));
  }
};

export const initializeConfirmIdentifyStep = currentCustomer => (
  initializeStep(CONFIRM_IDENTITY_STEP, { name: currentCustomer.name })
);

export const initializeCustomerCreationStep = ({ email, phoneNumber }) => (
  initializeStep(CUSTOMER_CREATION_STEP, {}, { email, phoneNumber })
);

export const initializeCustomerIdentificationStep = (previousMethod, previousPhoneNumber) => (
  initializeStep(CUSTOMER_IDENTIFICATION_STEP, { previousMethod }, { previousPhoneNumber })
);

export const initializeCarSelectionStep = () => initializeStep(CAR_SELECTION_STEP);
export const initializeCarCreationStep = () => (dispatch) => {
  dispatch(setLoadingState(true, DELAY_1000));
  const request = () => (
    fetchMakeModelYearMap()
      .then((response) => {
        dispatch({ type: FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS, payload: response });
        dispatch(setLoadingState(false));
        dispatch(initializeStep(CAR_CREATION_STEP));
      })
      .catch((error) => {
        dispatch(initializeErrorStep(error, true));
      })
  );
  dispatch(setLastRequest(request));
  request();
};

export const initializeServiceSelectionStep = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  const request = () => (
    fetchBookingServices(
      appBookingIdSelector(getState()),
      appDealershipIdSelector(getState()),
      chatCurrentVehicleSelector(getState()),
    )
      .then((response) => {
        dispatch(setLoadingState(false));
        dispatch({ type: FETCH_SERVICES_SUCCESS, payload: response });
        dispatch(initializeStep(SERVICE_SELECTION_STEP));
      })
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const selectAdvisor = (advisor, advisorIds, phantomAdvisorIds) => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true, selectedAdvisorName: advisor.name }));
  dispatch({ type: SELECT_ADVISOR, payload: { advisor } });
  dispatch({ type: SET_ADVISOR_IDS, payload: { advisorIds, phantomAdvisorIds } });
  dispatch({ type: SELECT_DATE, payload: { dateTime: null } });
  // eslint-disable-next-line no-use-before-define
  dispatch(initializeDateSelectionStep(advisorIds));
};

export const initializeAdvisorSelectionStep = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  const request = () => {
    const bookingId = appBookingIdSelector(getState());
    const payload = {
      vehicle_id: chatCurrentVehicleSelector(getState()).id,
      menu_items_ids: chatAllSelectedServicesSelector(getState()).map(({ id }) => id),
      recalls_ids: chatSelectedRecallsSelector(getState()).map(recall => recall.id),
    };
    fetchResponsibleTeamTag(bookingId, payload)
      .then((res) => {
        dispatch({ type: FETCH_TEAM_TAG_SUCCESS, payload: res });
        const responsibleTeamTag = chatResponsibleTeamTagSelector(getState());

        fetchTeamAdvisors(bookingId, responsibleTeamTag.id)
          .then(response => response
            .map(({
              service_advisor_profile: serviceAdvisorProfile,
            }) => serviceAdvisorProfile).filter(v => v))
          .then((teamAdvisors) => {
            if (!teamAdvisors.length) {
              return fetchAvailableAdvisors(bookingId, appDealershipIdSelector(getState()));
            }
            return teamAdvisors;
          })
          .then((response) => {
            dispatch({ type: FETCH_ADVISORS_SUCCESS, payload: { advisors: response } });
            dispatch(setLoadingState(false));

            if (chatIsPickUp(getState()) || chatIsMobileTechnician(getState())) {
              const selectedAdvisor = response[Math.floor(Math.random() * response.length)];
              let advisorIds = [];
              let phantomAdvisorIds = [];

              if (response.length > 1) {
                advisorIds = response.map(advisor => advisor.id);
                phantomAdvisorIds = response
                  .filter(advisor => advisor.is_phantom)
                  .map(advisor => advisor.id);
              } else {
                advisorIds.push(selectedAdvisor.id);
              }

              dispatch(selectAdvisor(selectedAdvisor, advisorIds, phantomAdvisorIds));
            } else {
              dispatch(initializeStep(
                ADVISOR_SELECTION_STEP,
                {},
                { selectedAdvisor: response[0] },
              ));
            }
          })
          .catch(error => dispatch(initializeErrorStep(error, true)));
      });
  };
  dispatch(setLastRequest(request));
  request();
};

export const fetchJobCreationSlots = (
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1,
) => (dispatch, getState) => {
  const request = () => {
    if (chatIsPickUp(getState())) {
      fetchDriverJobSlots(
        appBookingIdSelector(getState()),
        chatCurrentVehicleIdSelector(getState()),
        chatAllSelectedServicesSelector(getState()).map(({ id }) => id),
        chatSelectedRecallsSelector(getState()).map(recall => recall.id),
        month,
        year,
        chatRemoteZoneSelector(getState()),
      )
        .then((response) => {
          dispatch({ type: FETCH_JOB_SLOTS_SUCCESS, payload: response });
          fetchDrivers(appBookingIdSelector(getState()))
            .then((drivers) => {
              dispatch({ type: FETCH_DRIVERS_SUCCESS, payload: drivers });
              dispatch(setLoadingState(false));
              dispatch(initializeAdvisorSelectionStep());
            })
            .catch(error => dispatch(initializeErrorStep(error, true)));
        })
        .catch(error => dispatch(initializeErrorStep(error, true)));
    } else if (chatIsMobileTechnician(getState())) {
      fetchTechnicianJobSlots(
        appBookingIdSelector(getState()),
        chatCurrentVehicleIdSelector(getState()),
        chatAllSelectedServicesSelector(getState()).map(({ id }) => id),
        chatSelectedRecallsSelector(getState()).map(recall => recall.id),
        month,
        year,
        chatRemoteZoneSelector(getState()),
      )
        .then((response) => {
          dispatch({ type: FETCH_JOB_SLOTS_SUCCESS, payload: response });
          fetchTechnicians(appBookingIdSelector(getState()))
            .then((technicians) => {
              dispatch({ type: FETCH_TECHNICIANS_SUCCESS, payload: technicians });
              dispatch(setLoadingState(false));
              dispatch(initializeAdvisorSelectionStep());
            })
            .catch(error => dispatch(initializeErrorStep(error, true)));
        })
        .catch(error => dispatch(initializeErrorStep(error, true)));
    }
  };
  dispatch(setLastRequest(request));
  request();
};

export const initializeDateSelectionStep = (
  advisorIds = [],
  dateReselection = false,
  allowReselection = true,
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1,
) => (dispatch, getState) => {
  if (allowReselection) {
    dispatch(setLoadingState());
  }
  const request = () => {
    dispatch(setLoadingState());
    fetchAppointmentSlots(
      appBookingIdSelector(getState()),
      {
        service_advisors_ids: advisorIds,
        dealership_id: appDealershipIdSelector(getState()),
        vehicle_id: chatCurrentVehicleSelector(getState())?.id,
        menu_items_ids: chatAllSelectedServicesSelector(getState()).map(({ id }) => id),
        recalls_ids: chatSelectedRecallsSelector(getState()).map(recall => recall.id),
        year,
        month,
        client_waiting: chatCustomerWaitingSelector(getState()),
      },
    )
      .then((response) => {
        dispatch({ type: FETCH_APPOINTMENT_SLOTS_SUCCESS, payload: response });

        if (chatIsPickUp(getState())) {
          fetchDriverJobSlots(
            appBookingIdSelector(getState()),
            chatCurrentVehicleIdSelector(getState()),
            chatAllSelectedServicesSelector(getState()).map(({ id }) => id),
            chatSelectedRecallsSelector(getState()).map(recall => recall.id),
            month,
            year,
            chatRemoteZoneSelector(getState()),
          )
            .then((jobSlots) => {
              dispatch({ type: FETCH_JOB_SLOTS_SUCCESS, payload: jobSlots });
            })
            .catch(error => dispatch(initializeErrorStep(error, true)));
        } else if (chatIsMobileTechnician(getState())) {
          fetchTechnicianJobSlots(
            appBookingIdSelector(getState()),
            chatCurrentVehicleIdSelector(getState()),
            chatAllSelectedServicesSelector(getState()).map(({ id }) => id),
            chatSelectedRecallsSelector(getState()).map(recall => recall.id),
            month,
            year,
            chatRemoteZoneSelector(getState()),
          )
            .then((jobSlots) => {
              dispatch({ type: FETCH_JOB_SLOTS_SUCCESS, payload: jobSlots });
            })
            .catch(error => dispatch(initializeErrorStep(error, true)));
        }

        dispatch(setLoadingState(false));

        if (allowReselection) {
          if (dateReselection) {
            dispatch(initializeStep(DATE_RESELECTION_STEP, {}, { tryAgain: true }));
          } else {
            dispatch(initializeStep(DATE_SELECTION_STEP));
          }
        }
      })
      .catch(error => dispatch(initializeErrorStep(error, true)));
  };
  dispatch(setLastRequest(request));
  request();
};

export const initializeRemoteServiceStep = () => (dispatch) => {
  dispatch(initializeStep(REMOTE_SERVICE_STEP, {}, {}));
};

export const initializeJobCreationStep = () => (dispatch, getState) => {
  dispatch(setIsPickUp(false));
  dispatch(setIsMobileTechnician(false));
  dispatch(setSkipPickUp(false));
  dispatch(setSkipMobileTechnician(false));
  dispatch(setLoadingState());
  const { totalPrice, totalHours } = chatBookingSelector(getState());
  const pickupEnabled = appPickupEnabled(getState());
  const mobileTechnicianEnabled = (
    appMobileTechnicianEnabled(getState()) && chatMobileTechnicianAvailableSelector(getState())
  );

  const request = () => (
    fetchJobsEnabledMoneyDurationValidation(
      appBookingIdSelector(getState()),
      totalPrice,
      totalHours,
    )
      .then(({ available }) => {
        dispatch(setPickUpAvailable(available));

        if ((pickupEnabled && available) || mobileTechnicianEnabled) {
          dispatch(initializeRemoteServiceStep());
        } else {
          dispatch(initializeAdvisorSelectionStep());
        }
        dispatch(setLoadingState(false));
      })
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

const initializeBookingSummaryStep = () => (dispatch) => {
  dispatch(setLoadingState());
  setTimeout(() => {
    dispatch(setLoadingState(false));
    dispatch(initializeStep(BOOKING_SUMMARY_STEP));
    setTimeout(() => dispatch(updateCurrentStep({ showSummaryDelayFinished: true })), DELAY_1500);
  }, DELAY_1500);
};

const initializeTransportSelectionStep = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  const { totalPrice, totalHours } = chatBookingSelector(getState());
  const isMobileTechnician = chatIsMobileTechnician(getState());
  const isPickUp = chatIsPickUp(getState());

  const request = () => (
    fetchAvailableTransportsInWidget(
      appBookingIdSelector(getState()),
      Math.round(totalPrice),
      totalHours,
      chatSelectedDateTimeSelector(getState()),
    ).then((response) => {
      dispatch({ type: FETCH_AVAILABLE_TRANSPORTS_SUCCESS, payload: response });
      const isTransportCompatible = (!response.transports.length)
        || (isPickUp && !response.transports.some(t => t.kind === 'loaner'))
        || isMobileTechnician;
      if (isTransportCompatible) {
        dispatch(initializeBookingSummaryStep());
      } else {
        dispatch(initializeStep(TRANSPORT_SELECTION_STEP));
        if (isPickUp) {
          dispatch(updateCurrentStep({ }, { question: 'needsTransport?' }));
        }
      }
    })
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

const initializeCommunicationStep = () => initializeStep(COMMUNICATION_STEP);

const initializeFinalStep = () => (dispatch, getState) => {
  const state = getState();

  dispatch(setLoadingState(true));
  dispatch(initializeStep(FINAL_STEP));

  let serviceType = 'inhouse';

  if (chatIsPickUp(getState())) {
    serviceType = 'pickup';
  } else if (chatIsMobileTechnician(getState())) {
    serviceType = 'mobile_technician';
  }

  const request = () => addBookingAppointment(
    chatBookingSource(state),
    appBookingIdSelector(state),
    appDealershipIdSelector(state),
    state.chat,
    serviceType,
    chatPreferredAddressDataSelector(getState()),
  ).catch(error => dispatch(initializeErrorStep(error, true)));

  dispatch(setLastRequest(request));
  request();
};

export const submitCustomerIdentification = ({ phoneNumber, email }) => (dispatch, getState) => {
  dispatch(updateCurrentStep({ phoneNumber, email, isComplete: true }, { isComplete: true }));
  dispatch({ type: SET_EMBED_WIDGET_OPEN, payload: true });

  let searchType;
  let searchPhrase;

  if (phoneNumber) {
    dispatch({ type: SET_PHONE_NUMBER, payload: { preferredPhoneNumber: phoneNumber } });
    dispatch({ type: SET_ID_METHOD, payload: { method: ID_METHOD_PHONE_NUMBER } });
    searchType = 'phone_number';
    searchPhrase = phoneNumber;
  } else {
    dispatch({ type: SET_EMAIL, payload: { email } });
    dispatch({ type: SET_ID_METHOD, payload: { method: ID_METHOD_EMAIL } });
    searchType = 'email';
    searchPhrase = email;
  }
  dispatch(setLoadingState());

  if (!appBookingIdSelector(getState())) {
    dispatch(initializeWelcomeStep());
  } else {
    const request = () => (
      fetchBookingCustomer(
        appBookingIdSelector(getState()),
        searchPhrase,
        searchType,
      ).catch(error => dispatch(initializeErrorStep(error, true)))
    );
    dispatch(setLastRequest(request));
    request();
  }
};

export const confirmIdentity = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isComplete: true, isConfirmed: true }));
  dispatch(setLoadingState(true, DELAY_1000));

  const customer = chatCurrentCustomerSelector(getState());

  dispatch(actionCableListener({
    action: FETCH_VEHICLES_RESPONSE_TYPE,
    data: customer.vehicles,
  }));
};

export const denyIdentity = () => (dispatch, getState) => {
  const allCustomers = chatSelectedAllCustomersSelector(getState());
  const otherCustomers = chatSelectedOtherCustomersSelector(getState());
  if (otherCustomers.length > 0) {
    dispatch({
      type: FETCH_CUSTOMER_SUCCESS,
      payload: {
        allCustomers,
        otherCustomers: otherCustomers.slice(1),
        currentCustomer: otherCustomers[0],
      },
    });
    dispatch(initializeConfirmIdentifyStep(chatCurrentCustomerSelector(getState())));
  } else {
    dispatch(updateCurrentStep({ isComplete: true, isConfirmed: false }));
    // eslint-disable-next-line max-len
    const phoneNumber = chatPreferredPhoneNumberSelector(getState()) || chatPhoneNumberSelector(getState());
    dispatch(initializeCustomerCreationStep({ phoneNumber }));
  }
};

export const createCustomer = customer => (dispatch, getState) => {
  const firstName = customer.name.split(' ')[0];
  dispatch(updateCurrentStep({
    isComplete: true,
    name: firstName,
    companyName: customer.company_name,
  }));
  dispatch(setLoadingState());
  const request = () => (
    addCustomer(appBookingIdSelector(getState()), {
      full_name: customer.name,
      phone_number: customer.phone,
      email: customer.email,
      customer_type: customer.customer_type,
      company_name: customer.company_name,
      source: chatBookingSource(getState()),
    }).catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const selectCar = car => (dispatch, getState) => {
  const allCustomers = chatSelectedAllCustomersSelector(getState());
  const customer = allCustomers.filter(item => item.id === car.customerId);

  dispatch({ type: SET_VEHICLE_ID, payload: { id: car.id } });
  dispatch({ type: SET_CURRENT_CUSTOMER, payload: { ...customer[0], vehicles: car.vehicles } });

  dispatch(updateCurrentStep({ isComplete: true, selectedCarName: car.name }));
  dispatch({ type: SET_CURRENT_VEHICLE, payload: car });
  dispatch(initializeStep(MILEAGE_STEP));
};

export const selectNewCar = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true, selectedCarName: null }));
  dispatch(initializeCarCreationStep());
};

export const createCar = car => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true, selectedCarName: `${car.make} ${car.model}` }));
  dispatch({ type: SET_CURRENT_VEHICLE, payload: car });
  dispatch(initializeStep(MILEAGE_STEP));
};

export const updateMileage = newMileage => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isComplete: true, newMileage }));
  dispatch({ type: UPDATE_MILEAGE, payload: { mileage: newMileage } });
  dispatch(setLoadingState());
  const vehicle = chatCurrentVehicleSelector(getState());
  const customer = chatCurrentCustomerSelector(getState());
  const bookingId = appBookingIdSelector(getState());
  let request;
  if (vehicle.id) {
    request = () => (
      updateBookingVehicle(bookingId, vehicle)
        .catch(error => dispatch(initializeErrorStep(error, true)))
    );
  } else {
    request = () => (
      addBookingVehicle(bookingId, vehicle, customer)
        .catch(error => dispatch(initializeErrorStep(error, true)))
    );
  }
  dispatch(setLastRequest(request));
  request();
};

export const editBookingServices = services => (dispatch) => {
  dispatch({ type: SELECT_SERVICES, payload: { services } });
};

const allServicesIsMobile = (services, selectedRecalls = [], available = true) => {
  let result = available;

  // eslint-disable-next-line no-unused-expressions
  services?.maintenance.forEach((service) => {
    if (result) {
      result = service.team_tags
        ? service.team_tags.filter(team => team.mobile).length > 0
        : false;
    }
  });

  // eslint-disable-next-line no-unused-expressions
  services?.concern.forEach((service) => {
    if (result) {
      result = service.team_tags
        ? service.team_tags.filter(team => team.mobile).length > 0
        : false;
    }
  });

  selectedRecalls.forEach((recall) => {
    if (result) {
      result = recall.mobile_technician;
    }
  });

  return result;
};

export const selectServices = services => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isComplete: true, selectedServices: services }));
  dispatch({ type: SELECT_SERVICES, payload: { services } });
  dispatch({ type: SET_MOBILE_TECHNICIAN_AVAILABLE, payload: allServicesIsMobile(services) });

  const bookingId = appBookingIdSelector(getState());
  const { vin } = chatCurrentVehicleSelector(getState());
  dispatch(setLoadingState(true, DELAY_1500));
  const request = () => (
    fetchRecallsForBooking(bookingId, vin)
      .catch(error => dispatch(initializeErrorStep(error, true)))
  );
  dispatch(setLastRequest(request));
  request();
};

export const toggleRecall = service => ({
  type: TOGGLE_RECALL,
  payload: { service },
});

export const selectRecalls = selectedRecalls => (dispatch, getState) => {
  if (selectedRecalls === null) {
    dispatch({
      type: CLEAR_SELECTED_RECALLS,
    });
  } else {
    dispatch({
      type: SET_MOBILE_TECHNICIAN_AVAILABLE,
      payload: allServicesIsMobile(
        null,
        selectedRecalls,
        chatMobileTechnicianAvailableSelector(getState()),
      ),
    });
  }

  dispatch(updateCurrentStep({
    isComplete: true,
  }));
  dispatch(initializeJobCreationStep());
};

export const confirmJobCreation = () => (dispatch, getState) => {
  dispatch(updateCurrentStep(
    { isConfirmed: true },
    { isConfirmed: true },
  ));

  if (
    !chatSkipPickUpSelector(getState())
    && appPickupEnabled(getState())
    && chatPickUpAvailableSelector(getState())
  ) {
    dispatch(setIsPickUp(true));
  }
  if (
    chatSkipPickUpSelector(getState())
    || !chatPickUpAvailableSelector(getState())
    || !appPickupEnabled(getState())
  ) {
    dispatch(setIsMobileTechnician(true));
  }
};

export const denyJobCreation = ({ isCanceled, isConfirmed }) => (dispatch, getState) => {
  if (
    !chatSkipPickUpSelector(getState())
    && appPickupEnabled(getState())
    && chatPickUpAvailableSelector(getState())
  ) {
    dispatch({ type: DENY_PICKUP_JOBS_CREATION });
    dispatch(setSkipPickUp(true));
    dispatch(setIsPickUp(false));

    if (
      appMobileTechnicianEnabled(getState())
      && chatMobileTechnicianAvailableSelector(getState())
    ) {
      dispatch(updateCurrentStep({
        isComplete: false, isCanceled, isConfirmed, isDenied: true,
      }));
    } else {
      dispatch(updateCurrentStep({
        isComplete: true, isCanceled, isConfirmed, isDenied: true,
      }));
      setTimeout(() => dispatch(initializeAdvisorSelectionStep()), DELAY_1000);
    }
  } else {
    dispatch(setSkipMobileTechnician(true));
    dispatch(setIsMobileTechnician(false));
    dispatch(updateCurrentStep({
      isComplete: true, isCanceled, isConfirmed, isDenied: true,
    }));
    setTimeout(() => dispatch(initializeAdvisorSelectionStep()), DELAY_1000);
  }
};

export const resetAddress = () => (dispatch) => {
  dispatch(initializeStep(
    REMOTE_SERVICE_STEP,
    { isConfirmed: true, isIntroHidden: true },
    { isConfirmed: true },
  ));
};

export const selectDate = (
  date,
  time,
  dateTime,
  jobDateTime,
  tryAgain = false,
  advisor,
  employee = null,
) =>
  (dispatch, getState) => {
    const isWaiting = chatCustomerWaitingSelector(getState());
    dispatch({ type: SELECT_ADVISOR_DATE, payload: { advisor, employee } });
    dispatch(updateCurrentStep({
      isComplete: true,
      selectedDate: `${date.dayOfTheWeek}, ${format(date.fullDate, 'MMMM Do')}`,
      selectedTime: twelveHourTimeFormat(time),
    }));
    dispatch({ type: SELECT_DATE, payload: { dateTime } });
    dispatch({ type: SELECT_JOB_DATE, payload: { dateTime: jobDateTime } });
    setTimeout(() => {
      if (tryAgain) {
        dispatch(initializeFinalStep());
      } else if (isWaiting) {
        dispatch(initializeBookingSummaryStep());
      } else if (chatIsPickUp(getState()) || chatIsMobileTechnician(getState())) {
        dispatch(initializeTransportSelectionStep(JOB_TRANSPORT_SELECTION_STEP));
      } else {
        dispatch(initializeTransportSelectionStep());
      }
    }, DELAY_500);
  };

export const setIsWaitingAvailable = isWaiting => (dispatch) => {
  dispatch({ type: SET_CLIENT_WAITING, payload: { isWaiting } });
  dispatch(updateCurrentStep({ isWaiting, isComplete: true }));
  dispatch(initializeBookingSummaryStep());
};

export const setIsWaiting = isWaiting => (dispatch) => {
  dispatch({ type: SET_CLIENT_WAITING, payload: { isWaiting } });
};

export const setNeedsTransport = needsTransport => (dispatch) => {
  if (needsTransport) {
    dispatch(updateCurrentStep({ needsTransport }, { question: 'whichTransport?' }));
  } else {
    dispatch(updateCurrentStep({ needsTransport, isComplete: true }));
    dispatch(initializeBookingSummaryStep());
  }
};

export const setTransport = selectedTransport => (dispatch) => {
  dispatch({ type: SELECT_TRANSPORT, payload: { selectedTransport } });
  dispatch(updateCurrentStep({ isComplete: true, selectedTransport }));
  dispatch(initializeBookingSummaryStep());
};

export const setEmptyTransport = () => (dispatch) => {
  dispatch(initializeBookingSummaryStep());
};

export const confirmBooking = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  const customerType = chatCustomerTypeSelector(getState());
  if (customerType === BUSINESS_CUSTOMER_TYPE) {
    dispatch(initializeStep(APPRAISAL_SELECTION_STEP));
  } else {
    dispatch(initializeCommunicationStep());
  }
};

export const confirmNumber = phoneNumber => (dispatch, getState) => {
  const preferredPhoneNumber = phoneNumber || chatPhoneNumberSelector(getState());
  dispatch(updateCurrentStep({ isComplete: true, preferredPhoneNumber }));
  dispatch({ type: SET_PHONE_NUMBER, payload: { preferredPhoneNumber } });

  if (chatIsPickUp(getState()) || chatIsMobileTechnician(getState())) {
    dispatch(initializeFinalStep());
  } else {
    dispatch(initializeStep(APPRAISAL_SELECTION_STEP));
  }
};

export const confirmAddress = (address, addressData, geolocation) => (dispatch, getState) => {
  const preferredAddress = address
    || chatCustomerLastAddressSelector(getState())
    || chatCustomerAddressSelector(getState());
  let preferredAddressData = addressData
    || chatCustomerLastAddressDataSelector(getState())
    || chatCustomerAddressDataSelector(getState());

  const isPickUp = chatIsPickUp(getState());
  const isDropOff = chatIsDropOff(getState());
  const isMobileTechnician = chatIsMobileTechnician(getState());

  let jobType;

  if (isPickUp) {
    jobType = 'pickup';
  } else if (isDropOff) {
    jobType = 'dropoff';
  } else if (isMobileTechnician) {
    jobType = 'mobile_technician';
  }

  dispatch({ type: SET_JOB_TYPE, payload: jobType });

  const request = (prefAddress, prefAddressData) => fetchJobsEnabledDistanceValidation(
    appBookingIdSelector(getState()),
    prefAddressData,
    geolocation,
    jobType,
  )
    .then(() => {
      dispatch({
        type: SET_PREFERRED_ADDRESS,
        payload: {
          preferredAddress: prefAddress,
          preferredAddressData: prefAddressData,
        },
      });
    })
    .catch(error => dispatch(initializeErrorStep(error, true)));

  dispatch(setLastRequest(request));

  if ((isEmpty(preferredAddressData) || isNil(preferredAddressData)) && preferredAddress) {
    geocodeByAddress(preferredAddress).then((res) => {
      const addressComponents = res[0].address_components;
      preferredAddressData = formattedAddressData(addressComponents);
      request(preferredAddress, preferredAddressData);
    });
  } else {
    request(preferredAddress, preferredAddressData);
  }
};

export const declineCommunication = () => (dispatch, getState) => {
  dispatch({ type: SET_SEND_SMS, payload: { sendSms: false } });
  dispatch(updateCurrentStep({ isComplete: true, confirmedPhoneNumber: null }));

  if (chatIsPickUp(getState()) || chatIsMobileTechnician(getState())) {
    dispatch(initializeFinalStep());
  } else {
    dispatch(initializeStep(APPRAISAL_SELECTION_STEP));
  }
};

export const stopAnimations = () => ({ type: MARK_DONE_ANIMATIONS });

export const requestAppraisal = appraisalRequested => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true, appraisalRequested }));
  dispatch({ type: SET_APPRAISAL_REQUESTED, payload: { appraisalRequested } });
  dispatch(initializeFinalStep());
};

export const retrieveTeamTags = () => (dispatch, getState) =>
  fetchTeamTags(appBookingIdSelector(getState()))
    .then(response => dispatch({ type: FETCH_TEAM_TAGS_SUCCESS, payload: response }))
    .catch();

export const setBookingsSource = bookingSource => (dispatch) => {
  dispatch({ type: SET_BOOKING_SOURCE, payload: { bookingSource } });
};

export const setRemoteZone = zone => dispatch =>
  dispatch({ type: SET_REMOTE_ZONE, payload: zone });

export const setRemoteGeolocation = geolocation => dispatch =>
  dispatch({ type: SET_REMOTE_GEOLOCATION, payload: geolocation });

export const setTimecodes = timecodes => dispatch =>
  dispatch({ type: SET_TIMECODES, payload: timecodes });
