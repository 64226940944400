import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const AdvisorSelectionStep = ({ selectedAdvisorName, isComplete, isAnimationFinished }) => (
  <Fragment>
    {(!isComplete || (isComplete && selectedAdvisorName)) && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message="Please select your preferred service consultant."
          source={SOURCE_CONCIERGE}
          isClosing
          timecodes={{ from: 119000, to: 124000 }}
        />
      </AnimationGroup>
    )}
    {isComplete && selectedAdvisorName && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={selectedAdvisorName}
          source={SOURCE_USER}
          isOpening
        />
      </AnimationGroup>
    )}
  </Fragment>
);

AdvisorSelectionStep.propTypes = {
  selectedAdvisorName: string,
  isComplete: bool,
  isAnimationFinished: bool,
};

AdvisorSelectionStep.defaultProps = {
  selectedAdvisorName: '',
  isComplete: false,
  isAnimationFinished: false,
};

export default AdvisorSelectionStep;
