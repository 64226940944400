import React, { Fragment, useState } from 'react';
import { func, number, string, shape, arrayOf, bool } from 'prop-types';
import { DELAY_1500 } from 'shared/constants/delays';
import AnimationGroup from 'components/common/AnimationGroup';
import Animation from 'components/common/Animation';
import Button from 'components/common/Button';
import maintenanceIcon from 'assets/images/maintenance.svg';
import concernIcon from 'assets/images/concern.svg';

import ServiceSelector from './ServiceSelector';
import styles from './styles.scss';

const BookingSummaryStepInput = ({
  mobileTechnicianEnabled,
  onConfirm,
  onSubmitServices,
  isDisabled,
  isAnimationFinished,
  servicesPriceVisible,
  selectedServices: {
    maintenance: selectedMaintenance,
    concern: selectedConcern,
  },
  services: {
    maintenance,
    concern,
  },
}) => {
  const [maintenanceServices, setMaintenanceServices] = useState(selectedMaintenance || []);
  const [concernServices, setConcernServices] = useState(selectedConcern || []);
  const [isEditing, setIsEditing] = useState(false);

  const handleServicesSubmit = () => {
    setIsEditing(false);
    onSubmitServices({
      maintenance: maintenanceServices,
      concern: concernServices,
    });
  };

  return (
    !isDisabled && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <Animation delay={DELAY_1500}>
          <div className={styles.container}>
            {isEditing ? (
              <Fragment>
                <div className={styles.services}>
                  <ServiceSelector
                    icon={maintenanceIcon}
                    label="Maintenance"
                    availableServices={maintenance}
                    selectedServices={maintenanceServices}
                    servicesPriceVisible={servicesPriceVisible}
                    mobileTechnicianEnabled={mobileTechnicianEnabled}
                    onSubmit={setMaintenanceServices}
                  />
                  <ServiceSelector
                    icon={concernIcon}
                    label="Concern"
                    availableServices={concern}
                    selectedServices={concernServices}
                    servicesPriceVisible={servicesPriceVisible}
                    mobileTechnicianEnabled={mobileTechnicianEnabled}
                    onSubmit={setConcernServices}
                  />
                </div>
                {(!!maintenanceServices.length || !!concernServices.length) && (
                  <Button
                    className={styles.confirm}
                    onClick={handleServicesSubmit}
                    caption="Confirm"
                  />
                )}
              </Fragment>
            ) : (
              <div className={styles.buttons}>
                <Button
                  className={styles.edit}
                  isSecondary
                  caption="Edit"
                  onClick={() => setIsEditing(true)}
                />
                <Button
                  className={styles.confirm}
                  onClick={onConfirm}
                  caption="Confirm my booking"
                />
              </div>
            )}


          </div>
        </Animation>
      </AnimationGroup>
    )
  );
};

BookingSummaryStepInput.propTypes = {
  onConfirm: func.isRequired,
  onSubmitServices: func.isRequired,
  isDisabled: bool,
  isAnimationFinished: bool,
  services: shape({
    maintenance: arrayOf(shape({ id: number, name: string })),
    concern: arrayOf(shape({ id: number, name: string })),
  }).isRequired,
  selectedServices: shape({
    maintenance: arrayOf(shape({ id: number, name: string })),
    concern: arrayOf(shape({ id: number, name: string })),
  }).isRequired,
  mobileTechnicianEnabled: bool.isRequired,
  servicesPriceVisible: bool.isRequired,
};

BookingSummaryStepInput.defaultProps = {
  isDisabled: false,
  isAnimationFinished: false,
};

export default BookingSummaryStepInput;

